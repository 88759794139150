.L-R {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  width: 40%;

}
main{
  display: flex;
  height: 600px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;  
}
.cover_div {
  width: 300px;
  height: 300px;
  position: relative;
  perspective: 1000px; 
}

 .flip .front {
  transform: rotateY(-180deg);
}

.flip .back {
  transform: rotateY(0deg);
}

.login_div, .register_div {
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden; 
  transition: transform 0.1s;
}

.login_div.back, .register_div.back {
  transform: rotateY(180deg);
}

.decorative {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
}
/* styles/auth.css */

.input-container {
  position: relative;
  margin-bottom: 20px;
}
.input-container input:focus {
  border-color: #7b00ff;
}
input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.3s ease all;
  pointer-events: none;
  color: #999;
}

input:focus + .floating-label,
input:valid + .floating-label {
  top: -8px;
  left: 10px;
  font-size: 12px;
  color: #333;
}

.auth_button {
  display: block;
  width: 300px;
  padding: 10px;
  background-color: blueviolet;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.auth_button:hover {
  background-color: darkviolet;
  transition: background-color 0.3s ease;
}

fieldset {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
}

legend {
  font-size: 18px;
  font-weight: bold;
}

p {
  margin-top: 10px;
  font-size: 14px;
}

p button {
  background: none;
  border: none;
  color: blueviolet;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
.registerCollege{
  width: 300px;
}
.decorative h1{
  color: #333;
}
