/* Results.css */
.results-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 20px;
}

.results-container h2 {
  margin-bottom: 20px;
}

.results-container table {
  width: 100%;
  border: 2px solid black;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.results-container th,
.results-container td {
  padding: 10px;
  text-align: left;
  border: 1px solid black; 
}
.answer-key-table table {
  width: 100%;
  border: 2px solid black; 
  border-collapse: collapse;
  margin-bottom: 20px;
}

.answer-key-table th,
.answer-key-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid black; 
}

.results-container .view-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.results-container .view-button:hover {
  background-color: #0056b3;
}

.results-container .save-button,
.results-container .close-button {
  margin-right: 10px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.results-container .save-button {
  background-color: #28a745;
  color: white;
  border: none;
}

.results-container .save-button:hover {
  background-color: #218838;
}

.results-container .close-button {
  background-color: #dc3545;
  color: white;
  border: none;
}

.results-container .close-button:hover {
  background-color: #c82333
}
.results-table {
  width: 100%;
  border-collapse: collapse;
}

.results-table th, .results-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.results-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
  color: black;
}

.answer-item {
  margin-bottom: 5px;
}

.close-button,.save-button  {
  margin: 20px 30px 20px 10px ;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.close-button:hover,.save-button:hover {
  background-color: #0056b3;
}

