body{
  font-family: 'Times New Roman', Times, serif;
}
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  background-color: #fffafa00; 
  padding: 20px;
}

.calendar-container {
  width: 1000px;
  margin-bottom: 20px;
  background-color: #fffafa; 
  font-family: 'Times New Roman', Times, serif;
}
.rbc-toolbar{
  background-color: aquamarine;
}
.rbc-btn-group{
  background-color: #ddd;
}
.rbc-timeslot-group{
  height: 50px;
}
.rbc-event-content{
  color: #fff;
}
.table-container {
  width: 1100px;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

thead {
  background-color: red;
  color: white; 
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd; 
}

tr:nth-child(even) {
  background-color: #f2f2f2; 
}

tr:hover {
  background-color: #ddd; 
}

.missed-exam, .missed-event {
  background-color: red !important;
  color: white;
}

.calendar-event {
  padding: 5px;
  margin: 2px 0;
  border-radius: 4px;
}

.missed-event {
  background-color: red !important;
  color: white;
}

@media (max-width: 1200px) {
  .calendar-container, .table-container {
      width: 100%;
      max-width: 100%;
  }
}
