/* formbuilder.css */

body {
  font-family: 'Times New Roman', Times, serif;
    background-color: #E3DFFD;
  margin: 0;
  padding: 20px;
  min-height: 100vh;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: 1% 5% 0% 5%;
}

.form-builder,
.form-preview {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 45%; 
}

.form-builder {
  margin-right: 5%;
}

.form-preview {
  overflow-y: auto; 
  max-height: 80vh;
}

.form-builder h2,
.form-preview h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-builder .controls {
  margin-bottom: 20px;
}

.form-builder button {
  margin: 10px 10px;
  padding: 5px 10px;
  background-color: blueviolet;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-builder button:hover {
  background-color: darkviolet;
}

.fieldset {
  margin-bottom: 20px;
  border: 1px solid #ccc; 
  padding: 15px;
}


.legend {
  font-size: 16px;
  font-weight: bold;
  color: blueviolet;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input[type="text"],
.input-group input[type="radio"] {
  margin-bottom: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.radio_group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio_group_preview {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  gap: 20px;
}

.option_group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-group input[type="radio"] {
  margin-left: 5px;
  width: 20px;
}

.input-group input[type="text"] {
  width: 250px;
}
.option_group input[type="radio"] {
  margin-left: 5px;
  width: 20px;
}

.option_group input[type="text"] {
  width: 250px;
}
.radio_group_in_exam{
  display: flex;
}
.radio_group_in_exam input[type="radio"] {
  margin-left: 5px;
  width: 20px; 
}

.label_in_radio {
  width: 250px;display: block;
}
